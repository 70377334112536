import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Toast from '../../../../common/toast'
import {
  meetingCommentAdd,
  meetingMilestonAdd,
  getSingleMeetingTopicDetails,
  useUpdateMeetingLogs,
} from '../../../../api/services/meetingTopic.services'
import Card from '../../../MeetingMultiView/components/MeetingTopics/common/Card'
import { usePdf } from '@mikecousins/react-pdf'
import './popup.scss'
import { useTranslation } from 'react-i18next'
import { getConsolidatedConfigSettings, hasRole } from '../../../../utils/auth'
import { meetingMultiViewSubRoutes, routes } from '../../../../utils/routes'
import { getObjectivUserList } from '../../../../api/services/employee.services'
import { useMeetingRecording } from '../../../../utils/meetingRecordingContext/meetingRecordingContext'
import MeetingTranscription from '../../../../components/MeetingTranscription'
import Modal from '../../../../components/Modal'
import InPageLoader from '../../../../components/InPageLoader'

const MeetingDeck = ({
  isModalOpen,
  closeModal,
  umtId,
  topicType,
  readOnly,
  redirectTo,
  fromKanban,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isDocumentLoading, setIsDocumentLoading] = useState(true)

  const navigate = useNavigate()
  const { t } = useTranslation(['Common', 'MeetingHub'])

  const [objData, setObjData] = useState(null)
  const [currentSelection, setCurrentSelection] = useState([])
  const [page, setPage] = useState(1)
  const canvasRef = useRef(null)
  const containerRef = useRef(null)
  const [scale, setScale] = useState(1.0)
  const initialScale = useRef(1.0)
  const [userList, setUserList] = useState([])
  const { state, dispatch, updateTopicFocusFn } = useMeetingRecording()
  const canUseMeetingSummarization =
    getConsolidatedConfigSettings('can_use_meeting_summarization') === true

  const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)

  const pdf_Url = `${BASE_URL}/get_meeting_deck?umtId=${umtId}&topicType=${
    topicType != 'undefined' ? topicType : 1
  }`

  const { pdfDocument, pdfPage } = usePdf({
    file: pdf_Url,
    page,
    canvasRef,
    scale,
  })

  const [flag, setFlag] = useState(false)
  const [resolved, setResolved] = useState(false)

  const handleKeyDown = (event) => {
    switch (event.keyCode) {
      case 37: // Left arrow key
        setPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage))
        break
      case 39: // Right arrow key
        if (pdfDocument && page < pdfDocument.numPages) {
          setPage((prevPage) => prevPage + 1)
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [pdfDocument, page])

  useEffect(() => {
    if (pdfPage && containerRef.current) {
      const viewport = pdfPage.getViewport({ scale: 1.0 })
      const containerWidth = containerRef.current.clientWidth
      const newScale = containerWidth / viewport.width
      setScale(newScale)
      initialScale.current = newScale
      setIsDocumentLoading(false)
    }
  }, [pdfPage, containerRef.current])

  // Reset when modal closes
  useEffect(() => {
    if (!isModalOpen) {
      setPage(1)
    }
  }, [isModalOpen])

  const getAllEmployees = async () => {
    const response = await getObjectivUserList(0)
    if (response?.employees) {
      setUserList(response.employees.map((emp) => ({ display: emp.name, id: emp.eId })))
    }
  }

  useEffect(() => {
    getScreenData()
    getAllEmployees()
  }, [])

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.keyCode) {
        case 37: // Left arrow key
          setPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage))
          break
        case 39: // Right arrow key
          if (pdfDocument && page < pdfDocument.numPages) {
            setPage((prevPage) => prevPage + 1)
          }
          break
        default:
          break
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [pdfDocument, page])

  async function getScreenData() {
    const postObj = { umtId: umtId, topicType: topicType }
    const result = await getSingleMeetingTopicDetails(postObj)

    if (result) {
      if (result?.blockedObjectives.length > 0) {
        setObjData(result?.blockedObjectives[0])
        setCurrentSelection(result?.blockedObjectives)
        setFlag(result?.blockedObjectives[0]?.isPriority)
        setResolved(!result?.blockedObjectives[0]?.status)
      } else if (result?.decisionObjectives.length > 0) {
        setObjData(result?.decisionObjectives[0])
        setCurrentSelection(result?.decisionObjectives)
        setFlag(result?.decisionObjectives[0]?.isPriority)
        setResolved(!result?.decisionObjectives[0]?.status)
      } else if (result?.updateObjectives.length > 0) {
        setObjData(result?.updateObjectives[0])
        setCurrentSelection(result?.updateObjectives)
        setFlag(result?.updateObjectives[0]?.isPriority)
        setResolved(!result?.updateObjectives[0]?.status)
      } else if (result?.adminTopicObjectives.length > 0) {
        setObjData(result?.adminTopicObjectives[0])
        setCurrentSelection(result?.adminTopicObjectives)
        setFlag(result?.adminTopicObjectives[0]?.isPriority)
        setResolved(!result?.adminTopicObjectives[0]?.status)
      } else {
        closeModal()
      }
      setIsLoading(false)
    } else {
      setIsLoading(false)

      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  async function createActivity(data, comment, index, mentions = []) {
    const formData = {
      milestoneDesc: comment,
      goalId: data?.objectiveId,
      mentions: mentions.map((mention) => mention.id),
      umtId: data?.umtId,
    }

    const result = await meetingMilestonAdd(formData)
    if (result) {
      getScreenData()
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.actionUpdatedSuccesfully'),
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  async function createComment(data) {
    const result = await meetingCommentAdd(data)
    if (result) {
      getScreenData()
      Toast.fire({
        icon: 'success',
        title: t('Common:modalMessages.commentUpdatedSuccessfully'),
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const mutationUpdateMeetingLogs = useUpdateMeetingLogs()

  async function createFlagStatus(data, type) {
    const formData = {}
    if (type === 'flag') {
      formData.isPriority = !data?.isPriority
    } else if (type === 'status') {
      formData.status = !data?.status
    }
    formData.umtId = data?.umtId

    await mutationUpdateMeetingLogs.mutateAsync(formData, {
      onSuccess: () => {
        getScreenData()
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      },
    })
  }

  const closeMeetingDeckView = () => {
    const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5

    let targetUrl
    if (redirectTo === 'kanban') {
      targetUrl = `/${routes.kanban}`
    } else if (redirectTo === 'decisionLog') {
      if (hasRole('team') && !isDelegateTl) {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.decisionLog}`
      } else {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.decisionLog}`
      }
    } else if (redirectTo === 'myDecisionHistory') {
      if (hasRole('team') && !isDelegateTl) {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.myDecisions}`
      } else {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.myDecisions}`
      }
    } else if (redirectTo === 'meetingTopics') {
      if ((hasRole('team') || hasRole('ic')) && !isDelegateTl) {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.meetingTopics}`
      } else {
        targetUrl = `/${routes.meetingMultiView}/${meetingMultiViewSubRoutes.meetingTopics}`
      }
    } else if (redirectTo === 'home') {
      targetUrl = `/${routes.home}`
    }

    const filters = {
      readOnly: readOnly,
    }

    navigate(targetUrl, { state: { data: filters } })
  }

  const updateCardRecordingState = async (umtId, objId, newValue, newState) => {
    dispatch({
      type: 'SET_CARD_RECORDING_STARTED',
      umtId: umtId,
      newValue: newValue,
    })
    await updateTopicFocusFn(umtId, objId, newState, currentSelection, getScreenData)
  }

  return (
    <Modal
      closeModal={closeModal}
      includeCloseIcon={false}
      width="100%"
      height="100%"
      style={{ width: '100%', height: '100%' }}
      modalContainerStyle={{ padding: '0' }}
    >
      <div>
        {isLoading && <InPageLoader inComponent={true} />}

        {!isLoading && (
          <div className="slider-wrapper" style={{ height: 'unset', width: '95vw' }}>
            <div
              ref={containerRef}
              className="slide-container"
              style={{
                overflow: 'auto',
                maxWidth: '95vw',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '20px',
              }}
            >
              {Boolean(pdfDocument && pdfDocument.numPages) && (
                <>
                  <nav style={{ width: '100%', marginBottom: '1rem' }}>
                    <ul className="pager">
                      <li className="scale" style={{ display: 'flex', gap: '1rem' }}>
                        <button
                          disabled={scale > initialScale.current * 1.1 ** 3}
                          onClick={() => setScale((s) => s * 1.1)}
                          className="fitted-button blue"
                        >
                          <span className="material-symbols-outlined icon-size-l">zoom_in</span>
                        </button>
                        <button
                          disabled={scale <= initialScale.current}
                          onClick={() => setScale((s) => s / 1.1)}
                          className="fitted-button blue"
                        >
                          <span className="material-symbols-outlined icon-size-l">zoom_out</span>
                        </button>
                        <button
                          disabled={page === 1}
                          onClick={() => setPage((p) => p - 1)}
                          className="fitted-button blue"
                        >
                          <span className="material-symbols-outlined">arrow_back_ios</span>
                        </button>
                      </li>
                      <li className="next" style={{ display: 'flex', gap: '1rem' }}>
                        <button
                          disabled={page === pdfDocument.numPages}
                          onClick={() => setPage((p) => p + 1)}
                          className="fitted-button blue"
                        >
                          <span className="material-symbols-outlined">arrow_forward_ios</span>
                        </button>
                        <button onClick={() => closeModal()} className="fitted-button blue">
                          <span className="material-symbols-outlined">close</span>
                        </button>
                      </li>
                    </ul>
                  </nav>
                  {!isDocumentLoading && (
                    <div style={{ width: 'fit-content' }}>
                      <canvas ref={canvasRef} />
                    </div>
                  )}
                </>
              )}
            </div>
            {!fromKanban && (
              <div className="comments-wrapper">
                {objData && (
                  <Card
                    data={objData}
                    createFlagStatus={createFlagStatus}
                    flag={flag}
                    createComment={createComment}
                    createActivity={createActivity}
                    showFull={false}
                    loadAgain={getScreenData}
                    refreshBGUScorecardFn={() => {}}
                    resolved={resolved}
                    viewOnlyMode={readOnly}
                    flagStatusLoading={
                      mutationUpdateMeetingLogs.isLoading || mutationUpdateMeetingLogs.isFetching
                    }
                    userList={userList}
                    updateCardRecordingState={updateCardRecordingState}
                    isPresentationMode={true}
                  />
                )}
                {canUseMeetingSummarization && state.hasSummarizationStarted && (
                  <div
                    style={{
                      border: '2px solid rgba(128, 128, 128, 0.24)',
                      width: '17rem',
                      height: '18rem',
                      overflowY: 'scroll',
                      overflowX: 'hidden',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div
                        style={{
                          display: 'flex',
                          paddingRight: '1rem',
                          paddingTop: '0.3rem',
                          paddingLeft: '0.5rem',
                          fontSize: '13px',
                          color: '#949494',
                        }}
                      >
                        {state.hasSummarizationStarted
                          ? t('MeetingHub:transcript.summarizingMeetingInProgress')
                          : t('MeetingHub:transcript.startSummarizingMeeting')}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textAlign: 'left',
                        marginLeft: '1rem',
                        marginTop: '1rem',
                        marginBottom: '0.3rem',
                        color: '#5780ca',
                      }}
                    >
                      {t('MeetingHub:transcript.title')}
                    </div>
                    <div className="divider"></div>
                    <MeetingTranscription />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  )
}

export default MeetingDeck
